import { videoTellingCaptionClasses as classes, StyledVideoTellingCaption } from "./StyledVideoTellingCaption";
import { Container, Grid, Typography } from "@mui/material";
import videoHero from "../../assets/videoHero.jpg";
import { BlueBanner } from '../../Components/BlueBanner';

export const VideoTellingCaption = () => {
    const mainContainerStyle = {
        backgroundImage: 'url('+videoHero+')',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    }

    const barContainerStyle = {
        backgroundColor: '#009ADE',
        textAlign: 'center',
    }

    return <StyledVideoTellingCaption className={classes.root}>
        <Grid item sx={mainContainerStyle} className="heroClass">
            <Container maxWidth={"lg"}>
                <Grid container sx={{paddingTop: "150px", paddingBottom: "55px", position: "relative", minHeight: {lg: "calc(100vh - 228px)"} }}>
                    <Grid item md={8} lg={6}>
                        <Grid container sx={{position: "relative", backgroundColor:"rgba(255,255,255,0.75)", padding:"40px 20px 20px", marginBottom: "40px" }}>
                            <Typography variant={"caption"} component={"div"} sx={{color: "#011E3F", textAlign: "left", zIndex: 100,  position: "relative"}}>
                            Video Telling
                            </Typography>
                            <Typography sx={{color: "#011E3F", margin: "30px 0 20px", textAlign: "left", fontSize: "20px"}}>
                            Grab your headphones and listen to LSP authors share firsthand experience on increasing the universal coverage of SRH services. This page also features our previously recorded webinars with authors, experts and country office representatives.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {/* FIXME: Dont do this*/}
                <Grid item sx={{"height": "50px", width: "100%"}} />
            </Container>
        </Grid>

        <BlueBanner />
        
    </StyledVideoTellingCaption>;
};
