import kazakhstan from '../../Stories/stories-images/kazakhstan.jpg';
import malawi from '../../Stories/stories-images/malawi.jpg';
import mexico from '../../Stories/stories-images/mexico.jpg';
import nepalChw from '../../Stories/stories-images/nepal_chw.jpg';

export interface IVideo {
    country: string;
    image: any;
    imagealt: string;
    person: string;
    title: string;
    watchTime: string;
    link: string;
    youtubeId: string;
}

export const videos: IVideo[] = [
    {
        country: 'Kazakhstan',
        image: kazakhstan,
        imagealt: "This picture reflects the context of the story, strengthening policies and legislation for better adolescent health in Kazakhstan",
        person: 'Dr Serik Tanirbergenov',
        title: 'Advancing adolescent health through policy and legislation',
        watchTime: '6 min watch',
        link: 'https://youtu.be/htKx3iGj85Y',
        youtubeId: 'htKx3iGj85Y',
    },
    {
        country: 'Nepal',
        image: nepalChw,
        imagealt: "This picture reflects the context of the story, women waiting for health services in Nepal",
        person: 'Dr Sabitri Sapkota and Ms Durpata Saud',
        title: 'Community health workers expand access to sexual and reproductive health services in rural areas',
        watchTime: '8 min watch',
        link: 'https://www.youtube.com/watch?v=yV2CJFXTlgw',
        youtubeId: 'yV2CJFXTlgw',
    },
    {
        country: 'Mexico',
        image: mexico,
        imagealt: "This picture reflects the context of the story, a waiting room for health services for indigenous communities in Mexico",
        person: 'Professor Gustavo Nigenda',
        title: 'Increasing access to obstetric care for indigenous women through a non-governmental model of care',
        watchTime: '8 min watch',
        link: 'https://youtu.be/QpK1vlXbmto',
        youtubeId: 'QpK1vlXbmto',
    },
    {
        country: 'Malawi',
        image: malawi,
        imagealt: "This picture reflects the context of the story, aligning donor priorities and funds for services such as maternal health in Malawi",
        person: 'Emily Chirwa',
        title: 'Aligning donor and government sexual and reproductive health financing in Malawi',
        watchTime: '4 min watch',
        link: 'https://youtu.be/Suk17pBL-V8',
        youtubeId: 'Suk17pBL-V8',
    }
]; 