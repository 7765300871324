import {Container, Grid, Typography} from "@mui/material";

export const BlueBanner = () => {
    const barContainerStyle = {
        backgroundColor: '#009ADE',
        textAlign: 'center',
    }

    return <Grid item sx={barContainerStyle}>
        <Container maxWidth={"xl"}>
            <Grid container sx={{padding: "15px 0", position: "relative" }}>
                <Grid item lg={12}>
                    <Grid container sx={{position: "relative", padding:"0", justifyContent: "center" }}>
                        <Typography variant={"h3"} sx={{color: "#FFF", fontWeight:"bold", margin: "0", textAlign: "center", fontSize: "20px", maxWidth: "1200px"}}>
                        In celebration of World Health Day, please join us on 9 April at 13:00 CET to engage with experts and implementers as we discuss a rural ambulance transport service in Punjab, Pakistan. Please register for the event <a href="https://who.zoom.us/webinar/register/WN_6YaVCHjgSBuDXkD_q0x4RQ#/registration" target="_blank" style={{color: "#FFF", textDecoration: "underline"}}>here</a>. <br/>
                        You can submit your questions for the implementers <a href="https://form.jotform.com/250794799639077" target="_blank" style={{color: "#FFF", textDecoration: "underline"}}>here</a>.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    </Grid>
};