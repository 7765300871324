import webinarSRHR from '../webinar-images/SRHR_in_UHC_plans_and_processes_in_Eastern_Europe_and_Central_Asia.png';
import webinarAdolescent from '../webinar-images/Strengthening_Adolescent-Responsive_Health_Systems.png';

export interface IWebinar {
    country: string;
    image: string;
    imagealt: string;
    title: string;
    description: string;
    watchTime: string;
    link: string;
    youtubeId: string;
}

export const webinars: IWebinar[] = [
    {
        country: 'Location',
        image: webinarSRHR,
        imagealt: "SRHR in UHC plans and processes in Eastern Europe and Central Asia",
        title: 'SRHR in UHC plans and processes in Eastern Europe and Central Asia',
        description: 'Shared experiences on integrating SRHR into the UHC agenda with WHO tools, guidance, and UNFPA/WHO support.',
        watchTime: '55 min watch',
        link: 'https://youtu.be/EDkaOrSA1DY',
        youtubeId: 'EDkaOrSA1DY',
    },
    {
        country: 'Location',
        image: webinarAdolescent,
        imagealt: "Strengthening Adolescent-Responsive Health Systems",
        title: 'Strengthening Adolescent-Responsive Health Systems',
        description: 'Experiences from Chile, Ethiopia, and Moldova integrating adolescent-friendly services into health systems.',
        watchTime: '1 hr watch',
        link: 'https://youtu.be/52ZX9jb1SZk',
        youtubeId: '52ZX9jb1SZk',
    },
] 